var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "login-parent" }, [
    _vm._m(0),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "login-form" },
      [
        _c("ank-authent", {
          attrs: {
            "default-language": "auto",
            "authent-languages": "fr-FR, en-US",
            "ns-sde": _vm.nsSde,
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "login-banner" }, [
      _c("img", {
        staticClass: "imgbanner",
        attrs: { src: "/CORE/Images/anakeen-logo.svg", alt: "Anakeen logo" },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }