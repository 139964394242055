/* eslint-disable @typescript-eslint/ban-ts-comment */
import { AnyObject, VueCompatibleDefinition } from "../../utils/vue";

export type I18nMixinData = {
  _i18n: boolean;
};

const I18nMixin: VueCompatibleDefinition<AnyObject, I18nMixinData> = {
  data() {
    return { _i18n: false };
  },
  beforeCreate(): void {
    if (this.$root.$i18n && !this.$_globalI18n.sharedVueI18n) {
      // Use root catalog as shared catalog
      this.$_globalI18n.sharedVueI18n = this.$root.$i18n;
    }

    if (this.$_globalI18n.sharedVueI18n) {
      // @ts-ignore
      this._i18n = this.$_globalI18n.sharedVueI18n;
    } else {
      // @ts-ignore
      this.$_globalI18n.sharedVueI18n = this._i18n;
      this.$_globalI18n.recordCatalog();
    }
    this.$_globalI18n.i18nBus.$on("localeLoaded", () => {
      // REEMIT localeLoaded to local components
      this.$emit("localeLoaded");
    });

    this.$_globalI18n.i18nBus.$on("localeChanged", (lang: string) => {
      this.$i18n.locale = lang;
      // REEMIT localeChanged to local components
      this.$emit("localeChanged", lang);
    });
    this.$i18n.locale = this.$_globalI18n.locale;
  },

  mounted(): void {
    if (this.$_globalI18n.loaded) {
      // Shared catalog already loaded
      this.$emit("localeLoaded");
    }
  }
};

// export mixin as any type for vue2/vue3 compatibility
export default I18nMixin as any;
