import { __decorate } from "tslib";
import Vue from "vue";
import AnkAuthent from "@anakeen/user-interfaces/components/lib/AnkAuthent.esm";
import { Component, Prop } from "vue-property-decorator";
let LoginPage = class LoginPage extends Vue {
};
__decorate([
    Prop({ type: String, default: "" })
], LoginPage.prototype, "nsSde", void 0);
LoginPage = __decorate([
    Component({
        components: {
            "ank-authent": AnkAuthent
        }
    })
], LoginPage);
export default LoginPage;
