import { AnyObject, VueCompatibleDefinition } from "../../utils/vue";

export type ReadyMixinData = {
  _ank_ready: boolean;
};

export type ReadyMixinMethods = {
  isReady(): boolean;
  _enableReady(): void;
};

const AnkReadyMixin: VueCompatibleDefinition<AnyObject, ReadyMixinData, ReadyMixinMethods> = {
  inject: {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    _ank_ready: false
  },
  methods: {
    isReady(): boolean {
      return this._ank_ready;
    },

    _enableReady(): void {
      const ready = () => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        this._ank_ready = true;
        this.$emit("ank-ready");
      };
      if (document.readyState === "loading") {
        document.addEventListener("DOMContentLoaded", ready);
      } else {
        ready();
      }
    }
  }
};
export default AnkReadyMixin as { inject: typeof AnkReadyMixin["inject"]; methods: typeof AnkReadyMixin["methods"] };
