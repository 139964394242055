/* eslint-disable @typescript-eslint/ban-ts-comment */

// @ts-ignore import vue2 types
import { CombinedVueInstance, Vue, CreateElement } from "vue/types/vue";
import { ThisTypedComponentOptionsWithRecordProps } from "vue/types/options";

// @ts-ignore import vue3 types
import { DefineComponent, ComponentPublicInstance, VNode, h } from "vue";

import * as Vue3 from "vue";
const allVue3 = Vue3 as any;
export const isVue3 = !!allVue3.h;

type IsVue3 = DefineComponent extends Record<string, unknown> ? true : false;

export type AnyFunction = (...args: any[]) => any;
export type AnyObject = Record<string, unknown>;
export type EmptyObject = Record<string, never>;
export type AnyRefs = Vue["$refs"] | EmptyObject;
export type CreateElementVue3 = typeof h;

type WithMixins<Key extends string, Source, Mixins> = Mixins extends Record<Key, unknown>
  ? Source & Mixins[Key]
  : Source;

export type VueCompatibleDefinition<
  Props,
  Data = AnyObject,
  Methods = AnyObject,
  Computed = AnyObject,
  Refs extends AnyRefs = AnyRefs,
  Mixins extends AnyObject = AnyObject,
  Inject = Vue
> = IsVue3 extends true
  ? DefineComponent<
      Props,
      AnyObject,
      Data,
      Computed,
      Methods,
      AnyObject,
      AnyObject,
      AnyObject,
      string,
      Props,
      Props,
      AnyObject
    > & { destroyed?(): void; beforeDestroy?(): void }
  : ThisTypedComponentOptionsWithRecordProps<
      Vue & Inject & { $refs: Refs },
      WithMixins<"data", Data, Mixins>,
      WithMixins<"methods", Methods, Mixins>,
      WithMixins<"computed", Computed, Mixins>,
      Readonly<WithMixins<"props", Props, Mixins>>
    > & { unmounted?(): void; beforeUnmount?(): void };

export type VueCompatibleInstance<T> = T extends VueCompatibleDefinition<
  infer Props,
  infer Data,
  infer Methods,
  infer Computed,
  infer Refs,
  infer Mixins,
  infer Inject
>
  ? IsVue3 extends true
    ? ComponentPublicInstance<Props, AnyObject, Data, Computed, Methods, AnyObject, Props>
    : CombinedVueInstance<Vue, Data, Methods, Computed, Props> & Inject & Mixins & { $refs: Refs }
  : never;

export type VueCompatibleSlotFunction = (...args: any[]) => VNode[];
export type VueCompatibleNamedSlots = Record<string, VueCompatibleSlotFunction>;
export type VueCompatibleCreateElementFunction = IsVue3 extends true ? CreateElementVue3 : CreateElement;

export function getVueCompatibleNamedSlotsObject(componentInstance: any): VueCompatibleNamedSlots {
  if (isVue3) {
    // force transtyping because in vue2 instance.$slots exists but is not slot functions
    // in vue3 $scopedSlots and $slots are unified into slot functions (https://v3-migration.vuejs.org/breaking-changes/slots-unification.html)
    return componentInstance.$slots as unknown as VueCompatibleNamedSlots;
  }
  return componentInstance.$scopedSlots;
}

export function getVueCompatibleNamedSlotFunction(
  componentInstance: any,
  slotName: string
): VueCompatibleSlotFunction | undefined {
  const slotsObject = getVueCompatibleNamedSlotsObject(componentInstance);
  return slotsObject[slotName];
}

export function hasVueCompatibleNamedSlot(componentInstance: any, slotName: string): boolean {
  const slotsObject = getVueCompatibleNamedSlotsObject(componentInstance);
  return !!slotsObject && !!slotsObject[slotName];
}
